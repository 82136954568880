.App {
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: auto;
}

.App .video {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

body,html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

/* ===== MODALS ===== */

.intro-modal-holder, .outro-modal-holder {
  position:absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.85);
  z-index: 999;
}
.outro-modal-holder {
  background-color: rgba(0,0,0);
}

.intro-modal, .outro-modal {
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.intro-modal > h2, .outro-modal > h2 {
  margin-top: 0;
}

/* ===== CONTROLS ===== */

.controls {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
}

.control-fullscreen > div {
  font-size: 52px;
  color: rgba(255,255,255,0.2);
  cursor: pointer;
}

.control-fullscreen > div:hover {
  color: rgba(255,255,255,1);
}

/* ===== ===== */
.bottom-hmi {
  display: grid;
  width: 100%;
  grid-template:
      "a a a"
      "b c d";
  grid-template-columns: 37vw 25vw auto;
  grid-template-rows: auto 25vh;
}

.cav-warning {
  min-height: 4em;
  background-color: black;
  grid-area: a;
  display: grid;
  grid-template:
      "a b c";
  grid-template-columns: 1fr auto 1fr;
  border-bottom: 1px solid white;
  grid-column-gap: 24px;
}

.cav-warning .cav-warning-data {
  grid-area: b;
  color: white;
}

.cav-warning .cav-warning-controls {
  grid-area: c;
  display: flex;
  justify-self: end;
  align-self: center;
  column-gap: 16px;
}

.cav-warning .cav-warning-controls .tts-button-holder {
  color: white;
  display: flex;
  align-items: center;
}

.cav-info {
  grid-area: d;
  background-color: black;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 10px 20px;
  row-gap: 15px;
  position: relative;
  max-height: 100%;
}

.tts-button-holder{
  cursor: pointer;
  padding: 5px;
}

.cav-info h3 {
  text-align: center;
  margin: 0;
  overflow-x: hidden;
  max-height: 100%;
}

.cav-info-data {
  width: 100%;
  align-items: center;
  flex: 1 1 auto;
  min-height: 0;
  display: flex;
  color: white;
  overflow: auto;
}

.cav-info-controls {
  margin-top: auto;
  column-gap: 24px;
  justify-items: center;
  align-self: end;
}

.cav-warning-controls button {
  color: white;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%);
  border: 1px solid white;
  cursor: pointer;
  width: 90px;
  height: 3.5em;
  padding: 0;
  position: relative;
  padding-top: 7px;
  width: 100%;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
}

.cav-info-controls button {
  color: white;
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%);
  border: 1px solid white;
  cursor: pointer;
  width: 90px;
  height: 2.5em;
  padding: 0;
  position: relative;
  padding-left: 17px;
  width: 100%;
  white-space: nowrap;
  padding-right: 10px;
}

.cav-info-controls button:hover, .cav-warning-controls button:hover {
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(99,99,99,1) 100%);
}

.cav-info-controls button:before, .cav-warning-controls button:before {
  content: "";
  width: 100%;
  height: 7px;
  background: linear-gradient(90deg, rgba(255,0,0,0.75) 0%, rgba(255,0,0,1) 35%, rgba(255,0,0,1) 65%, rgba(255,0,0,0.75) 100%);
  position: absolute;
  top: 0;
  left: 0;
}

.cav-info-controls button:before {
  content: "";
  width: 7px;
  height: 100%;
  background: linear-gradient(90deg, rgba(255,0,0,0.75) 0%, rgba(255,0,0,1) 35%, rgba(255,0,0,1) 65%, rgba(255,0,0,0.75) 100%);
  position: absolute;
  top: 0;
  left: 0;
}

.cav-info-controls button.active:before, .cav-warning-controls button.active:before {
  background: linear-gradient(90deg, rgba(0,255,0,0.75) 0%, rgba(0,255,0,1) 35%, rgba(0,255,0,1) 65%, rgba(0,255,0,0.75) 100%);
}

/* ===== Car data ===== */
.car-data {
  grid-area: b;
  width: 100%;
  background-color: black;
  display: flex;
}

.car-data-left {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.car-data-left .main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.car-data-right {
  flex: 1;
  width: 250px;
}

.car-data-left .top-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px 0;
}

/* ===== ===== */

.blinkers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

.blinker-left, .blinker-right {
  width: 34px;
  height: 34px;
}

.blinker-content {
	position: relative;
	height: 0px; width: 0px;
	border: 12px solid;
	border-color:
  rgba(50,50,50, 1)
  rgba(50,50,50, 1)
		transparent
		transparent;
}

.blinker-left .blinker-content {
	transform: rotate(-135deg) translate(-6px, 0px);
}

.blinker-right .blinker-content {
  transform: rotate(45deg) translate(7px, 0px);
}

.blinker-right .blinker-content:before {
	content: '';
	position: absolute;
	top: 0px; right: 0px;
	display: block;
	height: 12px; width: 16px;
	background-color: rgba(50,50,50, 1);
	transform:
		rotate(-45deg)
		translate(3px, 1px);
}

.blinker-left .blinker-content:after {
	content: '';
	position: absolute;
	top: 0px; right: 0px;
	display: block;
	height: 12px; width: 16px;
	background-color: rgba(50,50,50, 1);
	transform:
		rotate(-45deg)
		translate(3px, 1px);
}

.blinker-left.active .blinker-content:after, .blinker-right.active .blinker-content:before {
	background-color: rgba(0,185,0,1);
}

.active .blinker-content {
	border-color:
  rgba(0,185,0,1)
  rgba(0,185,0,1)
		transparent
		transparent;
}

/* ===== Clock ===== */
.clock {
  position: absolute;
}

.currentTime {
  color: white;
}

/* ===== Speed ===== */
.current-speed {
  color: white;
  align-self: center;

  /*TO CHECK*/
  position: absolute;
}

.speed {
  text-align: center;
  font-size: 64px;
  font-weight: 600;
  line-height: 64px;
}

.speed-unit {
  text-align: center;
  font-weight: 600;
}

/* ===== Car navigation ===== */
.car-navigation {
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  row-gap: 20px;
}

.car-navigation .eta {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.car-navigation .eta > div, .car-navigation .eta-details > div {
  flex: 1;
}

.eta-title {
  align-self: center;
}

.car-navigation .eta-details {
  display: flex;
  column-gap: 20px;
}

.eta-duration {
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
}

.eta-distance {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3px;
}

.car-navigation .instructions {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instruction-arrow {
  width: auto;
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 100%;
}

.instruction-arrow > img {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 2px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ===== max speed ===== */
.max-speed {
  align-self: center;
}

.max-speed .sign {
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: calc(30px + 10px);
  border: 10px solid red;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  font-weight: 600;
}

/* ===== battery lvl =====*/
.bottom-row{
  display: flex;
  justify-content: space-between;

  /* TO CHECK */
  /*height: 100%;*/
  margin-top: auto;
  margin-bottom: 6px;
}

.battery{
  border: 2px solid #fff;
  width: 28px;
  height: 18px;
  padding: 2px;
  border-radius: 4px;
  position: relative;
  margin: 15px;
  align-self: center;
}

.battery::before{
  content: '';
  height: 7px;
  width: 2px;
  background: #fff;
  display: block;
  position: absolute;
  left: -3px;
  top: 3px;
  border-radius: 4px 4px 0 0;
}

.battery-level{
  background: #30b455;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 20%;
}

.mapboxgl-canvas-container{
  height: 25vh !important;
  width: 400px !important;
}

.is-reached {
  background: black;
}

.is-reached .map-container {
  display: none;
}

.is-reached, .is-reached .map-destination-reached {
  width: 100%;
  height: 100%;
}

.is-reached .map-destination-reached {
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-destination-reached img {
  max-height: 100%;
  max-width: 100%;
}